
import {config} from '@/utils'

import homeTitlepc from '@/components/home/homeTitlepc.vue'
import Footer from '@/components/footer/index.vue'
import wx from "weixin-js-sdk"; 
export default {
    name: 'information',
    components: {
        homeTitlepc,
        Footer,
        
    },

    mounted () {
     
    },

    created(){
        this.init()
        

    },

    methods: {
        init(){
            let queryData = JSON.parse(localStorage.getItem('_RedirectData'))
            if(this.$route.query.hasOwnProperty('article_id')){
                this.article_id=this.$route.query.article_id
                this.see=this.$route.query.see
            }else{
                this.article_id=queryData.article_id
                this.see=queryData.see
            }
           
                if( this.see ==1){
                    this.$api.news.article_detail({article_id: this.article_id}).then(res => {
                        if ( res.status_code != 0) return this.$Message.warning({
                            content:  res.status_msg
                        })
                        this.num= res.obj
                        console.log(this.num);
                        if(res.obj.like_count !=0){
                            this.cStatus1=1
        
                        }
                        if(res.obj.collect_count !=0){
                            this.cStatus2=1
        
                        }
                        this.getCommentList()
                        if(this.token !=null){
                            this.getCollect()//收藏列表
    
                        }
    
                      
        
                    }).catch(err => {
                        console.log(err)
                    })
        
    
    
                }else{
                    this.$api.news.preview_detail({article_id:this.article_id}).then(res => {
                        if ( res.status_code != 0) return this.$Message.warning({
                            content:  res.status_msg
                        })
                        this.num= res.obj
                        
                      
        
                    }).catch(err => {
                        console.log(err)
                    })
    
                    
                }

           
        },
        getArticle(){
            this.$api.news.article_list({
                page:this.page,
                title:this.title,
                category_id: this.category_id,
            }).then(res => {
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                this.daList=res.list

            }).catch(err => {
                console.log(err)
            })

        },
        getList(){
            
            if(this.$route.query.see ==1){
                this.$api.news.article_detail({article_id: this.article_id,}).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.num= res.obj
                    console.log(this.num);
                    if(res.obj.like_count !=0){
                        this.cStatus1=1
    
                    }
                    if(res.obj.collect_count !=0){
                        this.cStatus2=1
    
                    }
                    this.getCommentList()
                    if(this.token !=null){
                        this.getCollect()//收藏列表

                    }

                  
    
                }).catch(err => {
                    console.log(err)
                })
    


            }else{
                this.$api.news.preview_detail({article_id: this.article_id}).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.num= res.obj
                    
                  
    
                }).catch(err => {
                    console.log(err)
                })

                
            }
        },
        // 点赞文章、评论
        
        addLike(e,v){
            if(e==1){
                this.$api.news.add_article_like({article_id:  this.article_id}).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.getList()
                    // this.$Message.warning({
                    //     content: "已点赞"
                    // })
                    this.cStatus1=1
    
    
                    // this.collectList= res.list
                  
    
                }).catch(err => {
                    console.log(err)
                })

            }else{
                this.$api.news.add_comment_like({comment_id: v.comment_id}).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.getCommentList()
                  
    
                }).catch(err => {
                    console.log(err)
                })



            }
            



        },
        //作者评论点赞
        addLikes(v){
            this.$api.news.add_comment_like({comment_id: v.reply_comment_id}).then(res => {
                if ( res.body.status_code != 0) return this.$Message.warning({
                    content:  res.body.status_msg
                })
                this.getCommentList()

            }).catch(err => {
                console.log(err)
            })
        },
        //收藏 
        addCollect(){
            this.$api.news.add_article_collect({article_id:  this.article_id}).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.getList()
                this.$Message.warning({
                    content: "已收藏"
                })
                this.cStatus2=1

            }).catch(err => {
                console.log(err)
            })

        },
        //取消收藏
        cancleCollect(v){
            this.$api.news.article_collect_cancel({article_id: v.article_id}).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.getCollect()
                this.getList()
            }).catch(err => {
                console.log(err)
            })


        },
        //查看收藏文章
        checkCollect(v){
            if( this.article_id==v.article_id){
                this.modal2=false
                this.modal3=false

            }else{
                this.$router.push({
                    path:'/details',
                    query:{article_id:v.article_id}
    
                })

            }
            

        },
        getCollect(){
            this.$api.news.collect_article_list({}).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.collectList= res.list
              

            }).catch(err => {
                console.log(err)
            })



        },
        // 评论列表
        getCommentList(){
            this.$api.news.comment_list({article_id:  this.article_id}).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.commentList= res.list
              

            }).catch(err => {
                console.log(err)
            })


        },
        //提交留言
        submit(){
            let is_anonymity
            if(this.single ==true){
                is_anonymity=1

            }else{
                is_anonymity=0
            }
            this.$api.news.add_comment({
                
                article_id: Number( this.article_id),
                content:this.value2,
                is_anonymity:is_anonymity
            }).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.value2=''
                this.single =false
                this.getCommentList()
              

            }).catch(err => {
                console.log(err)
            })



        },
        // 举报
        getReport(e,v){
            this.report_type=e
            if(e==2){
                this.comment_id=v.comment_id

            }
            this.$api.news.report_item_list({
                
                report_type: e,
            }).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
                this.reportList=res.list
                // this.value2=''
                this.modal1 =true
                // this.getCommentList()
              

            }).catch(err => {
                console.log(err)
            })

        },
        // 反馈记录
        record(){
            if(this.report_type ==1){
               
                this.$api.news.article_report_list({
                    
                }).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.articleList=res.list
                    this.modal1=false
                    this.modal2=true
                    
                  
    
                }).catch(err => {
                    console.log(err)
                })

            }else{
                
                this.$api.news.comment_report_list({
                    
                   
                }).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.articleList=res.list
                    this.modal1=false
                    this.modal2=true
                   
                  
    
                }).catch(err => {
                    console.log(err)
                })

            }

        },
        // 提交反馈
        submitRecord(){
            if(!this.formValidate.gender) return this.$Message.error({content: "请选择举报分类"})
            let arr=[]
            this.uploadList.forEach(ite => {
                arr.push(ite.file_path)
                
            });
            // 文章
            if(this.report_type ==1){
                this.$api.news.add_article_report({
                    
                    article_id: this.article_id,
                    suggest:this.suggest,
                    report_item_id:this.formValidate.gender,
                    img_paths:arr
                }).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.modal1=false
                    this.formValidate.gender=''
                    this.$Message.success({
                        content: "举报成功"
                    })
                  
    
                }).catch(err => {
                    console.log(err)
                })

            }else{
                this.$api.news.add_article_report({
                    
                    comment_id:this.comment_id,
                    suggest:this.suggest,
                    report_item_id:this.formValidate.gender,
                    img_paths:arr
                }).then(res => {
                    if ( res.status_code != 0) return this.$Message.warning({
                        content:  res.status_msg
                    })
                    this.modal1=false
                    this.formValidate.gender=''
                    this.$Message.success({
                        content: "举报成功"
                    })
                  
    
                }).catch(err => {
                    console.log(err)
                })

            }



        },


        handleView (name) {
            this.imgName = name;
            this.visible = true;
        },
        handleRemove (file) {
            const fileList = this.$refs.upload.fileList;
            this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
        },
        handleSuccess (response, file) {
            if(response.status_code==0){
                this.$Message.success({content:'上传成功'})
                this.uploadList.push(response.list[0]) 
              
            }else{
                this.$Message.warning({content: response.status_msg})
            }
        },
        handleFormatError (file) {
            this.$Notice.warning({
                title: 'The file format is incorrect',
                desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
            });
        },
        handleMaxSize (file) {
            this.$Notice.warning({
                title: 'Exceeding file size limit',
                desc: 'File  ' + file.name + ' is too large, no more than 2M.'
            });
        },
        handleBeforeUpload () {
            const maxCount = 3;
            if (this.$refs.upload.fileList.length == maxCount) {
              this.$Notice.error({ title: '错误', desc: '最多允许上传 ' + maxCount + ' 个文件' });
              return false;
            }
       
            // 通过判断，返回true继续上传
            return true;
        },
        // 返回
        go(e){
            if(e==1){
                this.$router.push({
                    path:'/',
    
                })
            }else{
                this.$router.push({
                    path:'/wap/information',
    
                })

            }

        },
        // 转发收集
        getShare(){
            this.$api.news.add_article_sharet({
                article_id:  this.article_id,
            }).then(res => {
                if ( res.status_code != 0) return this.$Message.warning({
                    content:  res.status_msg
                })
               
              

            }).catch(err => {
                console.log(err)
            })

        },
        //微信分享
        getShareInfo(){

             //获取url链接（如果有#需要这么获取）
            // var url = encodeURIComponent(window.location.href.split("#")[0]);
            //获取url链接（如果没有#需要这么获取）
            console.log(window.location.href);
            var url = window.location.href
            
            this.$api.news.jsconfig({
                url:url
            }).then(res => {
                this.getShare()
                wx.config({
                debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                appId: res.obj.jsconfig.appId, // 必填，公众号的唯一标识
                timestamp: parseInt(res.obj.jsconfig.timestamp), // 必填，生成签名的时间戳
                nonceStr: res.obj.jsconfig.nonceStr, // 必填，生成签名的随机串
                signature:res.obj.jsconfig.signature, // 必填，签名
                jsApiList: res.obj.jsconfig.jsApiList
                });
                wx.ready(() => {
                    var shareData = {
                        title: num.title,
                        desc: num.create_date,
                        link: window.location.href,
                        imgUrl: num.thumbnail_url
                    };
                    //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
                    wx.updateAppMessageShareData(shareData);
                    //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
                    wx.updateTimelineShareData(shareData);
                });
                //错误了会走 这里
                wx.error(function (res) {
                console.log("微信分享错误信息", res.status_msg);
                });
            });








        },
       
        
    },

    data () {
        return {
            config,
            article_id:'',
            see:'',
            token:localStorage.getItem('USER__TOKEN'),
            num:{},
            query:{},
            uploadList:[],
            suggestList:[],
            value2:"",//留言
            single: false,//匿名提交
            modal1: false,//举报反馈
            modal2:false,//我的反馈列表
            modal3:false,//我的收藏列表
            modal4:false,//分享
            commentList:[],//评论列表
            reportList:[],//举报选项
            articleList:[],//我的反馈列表
            suggest:'',//举报建议
            report_type:0,//举报文章、评论
            comment_id:0,//评论id
            cStatus1:0,
            cStatus2:0,
            cStatus3:0,
            collectList:[],
            imgName: '',
            visible: false,
            defaultList: [],
            //
            formValidate: {
               
                gender: '',
              
            },
            ruleValidate: {
            
                gender: [
                    { required: true, message: 'Please select gender', trigger: 'change' }
                ],
             
            }
           
           
            
        }
    }
}
